import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"

import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <h2>Pronto!</h2>
    <div className="col-md-6 col-12">
      <p>
        Em instantes você irá receber um e-mail de confirmação e as instruções
        de participação.
      </p>

      <p>
        Em caso de dúvidas ou sugestões entre em contato: events@kxp.consulting.
      </p>

      <nav>
        <Link
          style={{ fontWeight: 500, fontSize: "0.8rem", color: "limegreen" }}
          to={`/`}
        >
          Voltar
        </Link>
      </nav>
    </div>
  </Layout>
)

export default IndexPage
